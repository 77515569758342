import React, { useState, useEffect, useRef } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Filter from "./components/filter";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import DataGrid, {
  Column,
  Grouping,
  Paging,
  SearchPanel,
  HeaderFilter,
} from "devextreme-react/data-grid";
import DeleteProduct from "./Deleteproduct.js";
import AddProductmain from "./addproduct.js";
import EditProducts from "./EditProducts.js";
import {
  productList,
  productListFilter,
  deleteProduct,
  updateProductStatus,
  productUploadApi,
  productSampleXlApi,
} from "../api/apiIntegration.js";
import LoaderOwn from "../common/loaderOwn.js";
import { getUser } from "../auth/authService.js";

export default function Product() {
  const [exampleData, setExampleData] = useState([]);
  const [isExampleLoading, setIsExampleLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoading, setIsDataLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setproductToDelete] = useState(null);
  const fileInputRef = useRef(null);
  useEffect(() => {
    list();
  }, []);

  const handleDownload = async () => {
    setIsExampleLoading(true);
    const result = await productSampleXlApi();
    if (!result.success) {
      setIsExampleLoading(false);
      // alert(`Error: ${result.message}`);
    } else {
      setIsExampleLoading(false);
    }
  };
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await productList();
      if (response.success) {
        setData(response.data);
      } else {
        toast.error("Failed to fetch product list");
      }
    } catch (error) {
      toast.error("An error occurred while fetching products");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setShowEditOffcanvas(true);
  };
  const handleClose = () => {
    setSelectedProduct(null);
    setShowEditOffcanvas(false);
  };
  const handleUpdate = (updatedProduct) => {
    setData((prevData) =>
      prevData.map((product) =>
        product.ProductId === updatedProduct.ProductId
          ? updatedProduct
          : product
      )
    );
    setShowEditOffcanvas(false);
    toast.success("Product updated successfully");
  };

  const handleFilterApply = async (filterData) => {
    try {
      setIsLoading(true);
      const response = await productListFilter(filterData);
      if (response.success) {
        setData(response.data);
      } else {
        toast.error("Failed to apply filters");
      }
    } catch (error) {
      toast.error("An error occurred while filtering products");
    } finally {
      setIsLoading(false);
    }
  };
  const handleDelete = (Product) => {
    setproductToDelete(Product);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteProduct(productToDelete.ProductId);
      setData((prevData) =>
        prevData.filter(
          (Product) => Product.ProductId !== productToDelete.ProductId
        )
      );
      toast.success("Brand deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete brand.");
    } finally {
      setShowDeleteModal(false);
      setproductToDelete(null);
    }
  };
  const handleStatusToggle = async (Product, newStatus) => {
    var form = {
      id: Product.ProductId,
      status: newStatus,
    };
    try {
      const response = await updateProductStatus(form);
      if (response.success == true) {
        toast.success(response.message);
        list();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred while updating the status.");
      }
    }
  };
  const importProducts = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    try {
      setIsDataLoading(true);
      const response = await productUploadApi(formData);
      if (response.success === true) {
        toast.success(response.message);
        list();
        handleClose();
      } else {
        toast.error(response.msg ? response.msg : response.message);
      }
    } catch (error) {
      if (error.msg) {
        toast.error(error.msg);
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setIsDataLoading(false);
      event.target.value = null;
    }
  };
  const handleError = (event) => {
    event.target.src = "images/no_image.png";
  };

  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>

      <main className="main-panel">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12"></div>
              <div className="card card-chart">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0">Product</h4>
                  <div className="ms-auto right_btn d-flex justify-content-end">
                    {!isLoading && (
                      <Filter onApply={handleFilterApply} status={"product"} />
                    )}
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      accept=".xlsx, .xls"
                      onChange={importProducts}
                      ref={fileInputRef}
                    />
                    <Button
                      id="btn"
                      size="sm"
                      onClick={() => fileInputRef.current.click()}
                    >
                       {dataLoading ? "Uploading..." : "Import"}
                    </Button>
                    <AddProductmain onAdd={list} />
                    <Button
                       id="btn"
                      size="sm"
                      onClick={handleDownload}
                      disabled={isExampleLoading}
                    >
                      {isExampleLoading ? "Downloading..." : "Download Sample"}
                    </Button>
                  </div>
                </div>
                <div className="card-body pt-2 px-3">
                  {isLoading ? (
                    <LoaderOwn />
                  ) : (
                    <DataGrid
                      id="gridContainer"
                      dataSource={data}
                      keyExpr="ProductId"
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      showBorders={true}
                      width="100%"
                      rowAlternationEnabled={true}
                      hoverStateEnabled={true}
                    >
                      <HeaderFilter visible={false} />
                      <Paging defaultPageSize={8} />
                      <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                        placeholder="Search ..."
                      />
                      <Grouping autoExpandAll={false} />
                      <Column
                        dataField="ProductImage"
                        width={150}
                        cellRender={({ data }) =>
                          data.ProductImage ? (
                            <img
                              src={data.ProductImage}
                              alt="ProductImage"
                              style={{ maxWidth: "100%", height: "auto" }}
                              onError={handleError}
                            />
                          ) : (
                            <img
                              src="images/no_image.png"
                              alt="ProductImage"
                              style={{ maxWidth: "100%", height: "15%" }}
                            />
                          )
                        }
                      />
                      <Column
                        dataField="ProductName"
                        width={200}
                        cellRender={({ data }) => (
                          <div style={{ whiteSpace: "normal" }}>
                            {data.ProductName}
                          </div>
                        )}
                      />
                      <Column
                        dataField="ProductDesc"
                        width={350}
                        cellRender={({ data }) => (
                          <div
                            style={{
                              whiteSpace: "normal",
                              Width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {data.ProductDesc}
                          </div>
                        )}
                      />
                      <Column
                        dataField="ProductAlcoholPercentage"
                        caption="Alcohol %"
                        alignment="center"
                      />
                      <Column
                        dataField="ProductVolume"
                        caption="Volume"
                        alignment="center"
                        cellRender={({ data }) => (
                          <div>
                            {data.ProductVolume}
                            {data.ProductUnit}
                          </div>
                        )}
                      />
                      {/* <Column
                        dataField="ProductUnit"
                        caption="unit"
                        alignment="center"
                      /> */}
                      <Column dataField="ProductRegion" caption="Country" />
                      <Column dataField="CategoryName" caption="Category" />
                      <Column dataField="BrandName" caption="Brand" />
                      <Column
                        alignment="center"
                        caption="Status"
                        width={150}
                        cellRender={({ data }) => (
                          <button
                            id="active_deactive"
                            className={`btn btn-${
                              data.IsEnabled === true ? "success " : "danger "
                            }`}
                            // onClick={() => handleStatusToggle(data)}
                          >
                            {data.IsEnabled === true ? "Active" : "Deactive"}
                          </button>
                        )}
                      />
                      <Column
                        caption="Action"
                        alignment="center"
                        width={150}
                        cellRender={({ data }) => (
                          <Actions
                            data={data}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onToggleStatus={handleStatusToggle}
                          />
                        )}
                      />
                    </DataGrid>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>

      {selectedProduct && (
        <EditProducts
          onAdd={list}
          show={showEditOffcanvas}
          onClose={handleClose}
          ProductsData={selectedProduct}
          onUpdate={handleUpdate}
          placement={"end"}
        />
      )}
      <DeleteProduct
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
      />
    </>
  );
}
export function Actions({ data, onEdit, onDelete, onToggleStatus }) {
  const { IsEnabled } = data;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "auto",
      }}
    >
      <div className="dropdown" style={{ position: "absolute" }}>
        <a
          href="#"
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="option_icon">
            <img src="images/more_icon.svg" alt="More options" />
          </span>
        </a>
        <ul className="dropdown-menu" style={{ height: "auto" }}>
          <li>
            <a className="dropdown-item" href="#" onClick={() => onEdit(data)}>
              <img src="images/blue_edit_icon.svg" alt="Edit" />{" "}
              <span>Edit</span>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => onDelete(data)}
            >
              <img src="images/blue_delete_icon.svg" alt="Delete" />
              <span>Delete</span>
            </a>
          </li>
          {IsEnabled ? (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, false)}
              >
                <img src="images/disabled.png" alt="Deactive" />{" "}
                <span>Deactive</span>
              </a>
            </li>
          ) : (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => onToggleStatus(data, true)}
              >
                <img src="images/check-mark.png" alt="Active" />{" "}
                <span>Active</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
