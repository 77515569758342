import React, { useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Form from "react-bootstrap/Form";
import { ThreeDots } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";
import LoaderOwn from "../common/loaderOwn";
import Editor from "./components/Editor";
import { pageAdd, pageEdit, pageGet } from "../api/apiIntegration";
export default function TermsAndCondition() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [contentId, setContentId] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await pageGet(1);

      if (response && response.success) {
        setContent(response.data.content);
        setContentId(response.data.id);
      } else {
        // const errorMessage =
        //   response?.msg || response?.message || "An unexpected error occurred";
        // toast.error(errorMessage);
      }
    } catch (error) {
      // const errorMessage = error?.msg || error?.message || "An error occurred";
      // toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  const handleContent = (value) => {
    setContent(value);
  };
  const handleSubmit = async () => {
    if (content === "") {
      toast.error("Please enter content");
    } else {
      const data = {
        content: content,
        type: "terms",
      };

      try {
        setLoading(true);
        const response = await pageAdd(data);
        if (response.success) {
          fetchData();
          toast.success(response.message);
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const handleEdit = async () => {
    if (contentId === null) {
      toast.error("Id is required");
    } else if (content === "") {
      toast.error("Please enter content");
    } else {
      const data = {
        id: contentId,
        content: content,
        type: "terms",
      };
      try {
        setLoading(true);
        const response = await pageEdit(data);
        if (response.success) {
          fetchData();
          toast.success(response.message);
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>
      <main className="main-panel">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">Terms and Conditions</h4>
                  </div>
                  <div className="card-body pt-2 px-3">
                    {isloading ? (
                      <LoaderOwn />
                    ) : (
                      <Form>
                        <Form.Group
                          className="mb-2"
                          style={{ marginTop: "1%" }}
                          controlId="exampleForm.ControlInput1"
                        >
                          <Editor value={content} onChange={handleContent} />
                        </Form.Group>
                        <div className="d-flex justify-content-center">
                          <Button
                            style={{
                              backgroundColor: "rgb(10,105,214)",
                              width: "15%",
                              border: "none",
                            }}
                            variant="primary"
                            type="button"
                            disabled={loading}
                            className="centered-button-content"
                            onClick={() => {
                              contentId === null
                                ? handleSubmit()
                                : handleEdit();
                            }}
                          >
                            <div>
                              {loading && (
                                <ThreeDots
                                  color="#fff"
                                  height={20}
                                  width={30}
                                />
                              )}
                              {!loading && (
                                <span>
                                  {contentId === null ? "Add" : "Edit"}
                                </span>
                              )}
                            </div>
                          </Button>
                        </div>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>
    </>
  );
}
