import axios from "axios";
import config from "../common/config";
import { getToken } from "../auth/authService";

export const userList = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.userListUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const brandList = async () => {
  try {
    const response = await axios.get(config.brandListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const volumeunitList = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.volumeUnitListUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const brandAdd = async (formData) => {
  try {
    const response = await axios.post(config.brandAddUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const volumeunitAdd = async (form) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.volumeUnitAddUrl, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const brandEdit = async (formData) => {
  try {
    const response = await axios.post(config.brandEditUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const categoryList = async () => {
  try {
    const response = await axios.get(config.categoriesListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const categoryAdd = async (formData) => {
  try {
    const response = await axios.post(config.categoriesAddUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const categoryEdit = async (formData) => {
  try {
    const response = await axios.post(config.categoriesEditUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};

export const productList = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.ProductListUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const productTopItemCount = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.ProducttTopItemCountUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      success: true,
      count: response.data.enabled,
      message: response.data.message,
    };
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const productAdd = async (formData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.ProductAddUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const bannerAdd = async (formData) => {
  try {
    const response = await axios.post(config.bannerAddUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const productEdit = async (formData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.ProductEditUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const subCategoryList = async (id) => {
  try {
    const response = await axios.get(`${config.SubcategoriesListUrl}/${id}`);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const subCategoryListPages = async () => {
  try {
    const response = await axios.get(config.SubcategoriesListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const productDropDownList = async (CategoryId, SubCategoryId) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(
      `${config.ProductDropDownListUrl}`,
      {
        CategoryId,
        SubCategoryId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const subCategoryAdd = async (formData) => {
  try {
    const response = await axios.post(config.SubcategoriesAddUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const subCategoryEdit = async (formData) => {
  try {
    const response = await axios.post(config.SubcategoriesEditUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const orderList = async () => {
  try {
    const response = await axios.get(config.orderListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const userListFilter = async (filterData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.userListFilterUrl, filterData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const NotificationUserListFilter = async (filterData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(
      config.NotificationuserListFilterUrl,
      filterData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const wishlistListFillter = async (filterData) => {
  try {
    const response = await axios.post(config.wishlistFilterUrl, filterData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const PushNotificationstFillter = async (filterData) => {
  try {
    const response = await axios.post(
      config.PushNotificationFilterUrl,
      filterData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const EmailNotificationstFillter = async (filterData) => {
  try {
    const response = await axios.post(
      config.EmailNotificationFilterUrl,
      filterData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const SMSNotificationstFillter = async (filterData) => {
  try {
    const response = await axios.post(
      config.SMSNotificationFilterUrl,
      filterData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const WhatsappNotificationstFillter = async (filterData) => {
  try {
    const response = await axios.post(
      config.WhatsappNotificationFilterUrl,
      filterData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const productListFilter = async (filterData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.ProductFilterUrl, filterData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const purchaseListFilter = async (filterData) => {
  try {
    const response = await axios.post(config.orderFilterUrl, filterData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const userChart = async (filterData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.userListChartUrl, filterData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const unRedeemedChart = async () => {
  try {
    const response = await axios.get(config.unRedeemdedChartUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const userTotalChart = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.userCountChartUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};

export const top10CategoriesChart = async (body) => {
  try {
    const response = await axios.post(config.Top10CategoryUrl, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const top10BrandChart = async (body) => {
  try {
    const response = await axios.post(config.Top10BrandUrl, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const top10SubCategoriesChart = async (body) => {
  try {
    const response = await axios.post(config.Top10SubCategoryUrl, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const top10NationalityChart = async (body) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.Top10NationalityUrl, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error:", error);
      return null;
    }
  }
};
export const chartCurrentVsPrevious = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.userListChartCurrentVsPreviousUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const deleteBrand = async (brandId) => {
  try {
    const response = await axios.delete(`${config.deleteBrandUrl}/${brandId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting brand:", error);
    throw error;
  }
};
export const deleteProduct = async (ProductId) => {
  try {
    const response = await axios.delete(
      `${config.deleteProductUrl}/${ProductId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting Product:", error);
    throw error;
  }
};
export const deleteCategory = async (ProductCategoryId) => {
  try {
    const response = await axios.delete(
      `${config.deleteCategoryUrl}/${ProductCategoryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting Category:", error);
    throw error;
  }
};
export const deleteSubCategory = async (ProductSubCategoryId) => {
  try {
    const response = await axios.delete(
      `${config.deleteSubCategoryUrl}/${ProductSubCategoryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting SubCategory:", error);
    throw error;
  }
};
export const BannerList = async () => {
  try {
    const response = await axios.get(config.bannerListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};

export const competitionsList = async () => {
  try {
    const response = await axios.get(config.competitionsListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const competitionsDetails = async (id) => {
  console.log(id);
  try {
    const response = await axios.post(config.competitionsDetailsUrl, id);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const competitionEntriesList = async (competitionId) => {
  try {
    const response = await axios.get(
      `${config.competitionEntriesListUrl}/${competitionId}`
    );
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const competitionWinnerList = async (competitionId) => {
  try {
    const response = await axios.get(
      `${config.competitionWinnerListUrl}/${competitionId}`
    );
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const competitionEdit = async (formData) => {
  try {
    const response = await axios.post(config.competitionsEditUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const competitionAdd = async (formData) => {
  try {
    const response = await axios.post(config.competitionAddUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const updateCompetitionStatus = async (formData) => {
  try {
    const response = await axios.post(
      config.updateCompetitionStatusUrl,
      formData
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const deleteCompetition = async (competitionId) => {
  try {
    const response = await axios.delete(
      `${config.deleteCompetitionUrl}/${competitionId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting Competition:", error);
    throw error;
  }
};
export const updateBrandStatus = async (formData) => {
  try {
    const response = await axios.post(config.updateBrandStatusUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const updateProductStatus = async (formData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.updateProductStatusUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const updateCategoryStatus = async (formData) => {
  try {
    const response = await axios.post(config.updateCategoryStatusUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const updateSubCategoryStatus = async (formData) => {
  try {
    const response = await axios.post(
      config.updateSubCategoryStatusUrl,
      formData
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
// export const bannerAdd = async (formData) => {
//   try {
//     const response = await axios.post(config.bannerAddUrl, formData);
//     return response.data;
//   } catch (error) {
//     if (error.response.data) {
//       return error.response.data;
//     } else {
//       return null;
//     }
//   }
// };
export const bannerEdit = async (formData) => {
  try {
    const response = await axios.post(config.bannerEditUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const updateOrderStatus = async (form) => {
  try {
    const response = await axios.post(config.updateOrderStatusUrl, form);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};

export const updateBannerstatus = async (form) => {
  try {
    const response = await axios.post(config.updateBannerStatusUrl, form);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const WishlistList = async () => {
  try {
    const response = await axios.get(config.WishlistListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const updateUserstatus = async (form) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.updateUserStatusUrl, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const orderCategoryChart = async (form) => {
  try {
    const response = await axios.post(config.orderCategoryChartUrl, form);
    return response.data;
  } catch (error) {
    console.error("Error adding notification:", error);
    throw error;
  }
};
export const userDetails = async (id) => {
  console.log(id);
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.userdetailsUrl, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const userTransactions = async (id) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.userTransactionsUrl, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch transactions", error);
    return { success: false, error };
  }
};
export const ExpiringPoints = async (userId, month, year) => {
  const Data = {
    userId,
    month,
    year,
  };

  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }

    const response = await axios.post(config.expiringPointsUrl, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const TotalEarnedPoints = async (userId) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.TotalEarnedPointstUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch total earned points", error);
    return { success: false, error };
  }
};
export const PushNotificationsList = async () => {
  try {
    const response = await axios.get(config.PushNotificationsListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const addNotification = async (form) => {
  try {
    const response = await axios.post(config.PushNotificationAddUrl, form);
    return response.data;
  } catch (error) {
    console.error("Error adding notification:", error);
    throw error;
  }
};
export const AddNotification = async (form) => {
  try {
    const response = await axios.post(config.AddNotificationAddUrl, form);
    return response.data;
  } catch (error) {
    console.error("Error adding notification:", error);
    // Optionally, you could provide more specific error messages or handle different error statuses here.
    throw error;
  }
};
export const fetchEmailTemplate = async (TemplateName) => {
  try {
    const response = await axios.post(
      config.AddNotificationAddUrl,
      TemplateName // Adjust as needed
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching email template:", error);
    throw new Error("Failed to fetch email template");
  }
};
export const EmailaddNotification = async (form) => {
  try {
    const response = await axios.post(config.EmailNotificationAddUrl, form);
    return response.data;
  } catch (error) {
    console.error("Error adding notification:", error);
    throw error;
  }
};
export const EmailNotificationsList = async () => {
  try {
    const response = await axios.get(config.EmailNotificationListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const SmsNotificationsList = async () => {
  try {
    const response = await axios.get(config.SmsNotificationListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const SmsAddNotification = async (form) => {
  try {
    const response = await axios.post(config.SmsNotificationAddUrl, form);
    return response.data;
  } catch (error) {
    console.error("Error adding notification:", error);
    throw error;
  }
};
export const SMTPSettingsUpdate = async (form) => {
  try {
    const response = await axios.post(config.SMTPSettingsUrl, form);
    return response.data;
  } catch (error) {
    console.error("Error adding notification:", error);
    throw error;
  }
};
export const SMTPSettingsList = async () => {
  try {
    const response = await axios.get(config.SMTPSettingsListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const EmailTemplateList = async () => {
  try {
    const response = await axios.get(config.EmailTemplateListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const EmailTemplateEdit = async (formData) => {
  try {
    const response = await axios.post(config.EmailTemplateEditUrl, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in EmailTemplateEdit:", error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return {
        success: false,
        message: "An unexpected error occurred. Please try again later.",
      };
    }
  }
};
export const sendTestEmail = async (testEmail) => {
  try {
    const response = await axios.post(config.SendMailUrl, {
      to: testEmail,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending test email:", error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return { success: false, message: "An unexpected error occurred" };
    }
  }
};
export const WhatsAppNotificationsList = async () => {
  try {
    const response = await axios.get(config.WhatsappNotificationListUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const AddWhatsappNotification = async (form) => {
  try {
    const response = await axios.post(config.WhatsappNotificationAddUrl, form);
    return response.data;
  } catch (error) {
    console.error("Error adding notification:", error);
    throw error;
  }
};
export const topCategoryChartStatus = async (formData) => {
  try {
    const response = await axios.post(config.top5CategoryChartUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const topSubCategoryChartStatus = async (formData) => {
  try {
    const response = await axios.post(
      config.top5SubcategoriesChartUrl,
      formData
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const topBrandChartStatus = async (formData) => {
  try {
    const response = await axios.post(config.top5BrandChartUrl, formData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const topNationalityChartStatus = async (formData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(
      config.top5NationalityChartUrl,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const expiredPointsChart = async () => {
  try {
    const response = await axios.get(config.expiryPointsChartUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const expiredNext3MonthsPointsChart = async () => {
  try {
    const response = await axios.get(config.expiryPointsNext3MonthsChartUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const expiredNextCurrentMonthPointsChart = async () => {
  try {
    const response = await axios.get(config.expiryPointsCurrentMonthChartUrl);
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const orderTransactionChart = async (formData) => {
  try {
    const response = await axios.post(
      config.orderTransactionChartUrl,
      formData
    );
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const RedeemedPointsChart = async () => {
  try {
    const response = await axios.get(config.RedeemedPointsChartUrl);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching redeemed points data:", error);
      return null;
    }
  }
};
export const TopSpenderMonthChart = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.topSpenderMonthChartUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching redeemed points data:", error);
      return null;
    }
  }
};
export const TopSpenderWeekendChart = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.topSpenderWeekendChartUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching redeemed points data:", error);
      return null;
    }
  }
};
export const TopBrandMonthChart = async () => {
  try {
    const response = await axios.get(config.topBrandMonthChartUrl);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching redeemed points data:", error);
      return null;
    }
  }
};
export const WeekendTopSellingBrands = async () => {
  try {
    const response = await axios.get(config.topBrandWeekendChartUrl);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching redeemed points data:", error);
      return null;
    }
  }
};
export const YearVsLastYearCategoryApi = async () => {
  try {
    console.log(config.orderYearVsLastYearChartUrl);
    const response = await axios.get(config.orderYearVsLastYearChartUrl);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching redeemed points data:", error);
      return null;
    }
  }
};
export const productUploadApi = async (body) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    console.log(config.productUploadXlUrl);
    const response = await axios.post(config.productUploadXlUrl, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      console.error("Error fetching redeemed points data:", error);
      return null;
    }
  }
};
export const productSampleXlApi = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.productsampleXlUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer", // Important for handling binary data
    });

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "sample_product_upload.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return { success: true };
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const faqList = async () => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.get(config.faqListUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};

export const faqUpdateList = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.faqUpdateUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const faqAddList = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.faqAddUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const faqEnabled = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.faqEnabledUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const faqDelete = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.faqDeleteUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const pageAdd = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.pageAddUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const pageEdit = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.pageEditUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const pageGet = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }

    const url = `${config.pageShowUrl}?${
      data === 1 ? "type=terms" : "type=privacy"
    }`;
    console.log(url);

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // Return error response data or null if no response data exists
    return error.response?.data || null;
  }
};
export const userEdit = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.userEditUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
export const userDataVerify = async (data) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No token found");
    }
    const response = await axios.post(config.userdataVerifyUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response.data) {
      return error.response.data;
    } else {
      return null;
    }
  }
};
