import React, { useState } from "react";
import { Offcanvas, Form, Button } from "react-bootstrap";
import optionsData from "./nationality.json";
import { userEdit } from "../api/apiIntegration";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

const genderData = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];
function EditUser({ onAdd, show, onClose, userData, onUpdate, placement }) {
  const [formData, setFormData] = useState(userData);
  const [nationality, setNationalityData] = useState(formData.Nationality);
  const [firstName, setFirstNameData] = useState(formData.FirstName);
  const [lastName, setlastNameData] = useState(formData.LastName);
  const [phoneNumber, setPhoneNumberData] = useState(formData.PhoneNumber);
  const [email, setEmailData] = useState(formData.Email);
  const [location, setLocationData] = useState(formData.Location);
  const [loading, setLoading] = useState(false);
  const [gender, setGenderData] = useState(
    formData.Gender == 2 ? "Female" : formData.Gender == 1 ? "Male" : "Other"
  );
  const uniqueOptions = Array.from(
    new Set(optionsData.map((opt) => opt.nationality))
  ).map((nationality) => {
    return optionsData.find((opt) => opt.nationality === nationality);
  });
  const handleChange = () => {
    const body = {
      userId: formData.UserId,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      nationality: nationality,
      location: location,
      gender: gender == "Male" ? "1" : gender == "Female" ? "2" : "3",
    };
    handleSubmit(body);
  };

  const handleSubmit = async (body) => {
    try {
      setLoading(true);
      const response = await userEdit(body);
      if (response.success == true) {
        toast.success(response.message);
        onAdd();
        onClose();
      } else {
        toast.error(response.msg ? response.msg : response.message);
      }
    } catch (error) {
      if (error.msg) {
        toast.error(error.msg);
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Offcanvas show={show} onHide={onClose} placement={"end"}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Edit User</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleChange}>
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="FirstName"
              name="FirstName"
              value={firstName}
              onChange={(e) => setFirstNameData(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="LastName"
              name="LastName"
              value={lastName}
              onChange={(e) => setlastNameData(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="PhoneNumber"
              name="PhoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumberData(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              className="form-control"
              id="Email"
              name="Email"
              value={email}
              onChange={(e) => setEmailData(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <Form.Control
              as="select"
              value={gender}
              onChange={(e) => setGenderData(e.target.value)}
            >
              <option value="">Select...</option>
              {genderData.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nationality</Form.Label>
            <Form.Control
              as="select"
              value={nationality}
              onChange={(e) => setNationalityData(e.target.value)}
            >
              <option value="">Select...</option>
              {uniqueOptions.map((option) => (
                <option key={option.nationality} value={option.nationality}>
                  {option.nationality}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              className="form-control"
              id="Location"
              name="Location"
              value={location}
              onChange={(e) => setLocationData(e.target.value)}
            />
          </Form.Group>
          <div className="text-center mt-4">
            <Button
              id="btn1"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading && <ThreeDots color="#fff" height={20} width={30} />}
                {!loading && <span>Update</span>}
              </div>
            </Button>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default EditUser;
