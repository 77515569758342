import React, { useCallback, useState, useEffect } from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  onRowClick,
} from "devextreme-react/data-grid";
import Button from "react-bootstrap/Button";
import {
  faqAddList,
  faqDelete,
  faqEnabled,
  faqList,
  faqUpdateList,
} from "../api/apiIntegration";
import LoaderOwn from "../common/loaderOwn";

export default function FaqScreen() {
  const [data, setData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [faqAddQuestion, setFaqAddQuestion] = useState("");
  const [faqAddAnswer, setFaqAddAnswer] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    handleList();
  }, []);
  const list = async () => {
    try {
      setIsLoading(true);
      const response = await faqList();
      if (response.success == true) {
        setData(response.data);
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleList = () => {
    list();
  };
  const handleAddOpen = (show) => {
    setShowAddModal(show);
  };

  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    if (faqAddQuestion == "") {
      toast.error("Question is required");
    } else if (faqAddQuestion == "") {
      toast.error("Answer is required");
    } else {
      var data = {
        question: faqAddQuestion,
        answer: faqAddAnswer,
      };

      try {
        setAddLoading(true);
        const response = await faqAddList(data);
        if (response.success == true) {
          toast.success(response.message);
          setFaqAddQuestion("");
          setFaqAddAnswer("");
          handleAddOpen(false);
          handleList();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setAddLoading(false);
      }
    }
  };
  const FAQList = ({ dataHave, onPress }) => {
    const [rows, setRows] = useState(dataHave);
    const [showModal, setShowModal] = useState(false);
    const [faqQuestion, setFaqQuestion] = useState("");
    const [faqAnswer, setFaqAnswer] = useState("");
    const [faqId, setFaqId] = useState(null);
    const [editLoading, setEditLoading] = useState(false);
    const handleOpen = (show) => {
      setShowModal(show);
    };
    const handleToggle = (id) => {
      setRows((prevRows) =>
        prevRows.map((row) => {
          if (row.id === id) {
            handleEnabled({ id: id, enabled: !row.enabled });
            return { ...row, enabled: !row.enabled };
          }
          return row;
        })
      );
    };

    const handleEdit = (data) => {
      setFaqId(data.id);
      setFaqQuestion(data.question);
      setFaqAnswer(data.answer);
      handleOpen(true);
    };

    const handleEnabled = async (status) => {
      try {
        const response = await faqEnabled(status);
        if (response.success == true) {
          toast.success(response.message);
          onPress();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setEditLoading(false);
      }
    };
    const handleDelete = async (data) => {
      try {
        const response = await faqDelete({
          id: data.id,
          status: 0,
        });
        if (response.success == true) {
          toast.success(response.message);
          onPress();
        } else {
          toast.error(response.msg ? response.msg : response.message);
        }
      } catch (error) {
        if (error.msg) {
          toast.error(error.msg);
        } else {
          toast.error("An error occurred");
        }
      } finally {
        setEditLoading(false);
      }
    };
    const handleSubmitEdit = async (e) => {
      e.preventDefault();
      if (faqId == null) {
        toast.error("Id is required");
      } else if (faqQuestion == "") {
        toast.error("Question is required");
      } else if (faqAnswer == "") {
        toast.error("Answer is required");
      } else {
        var data = {
          id: faqId,
          question: faqQuestion,
          answer: faqAnswer,
        };

        try {
          setEditLoading(true);
          const response = await faqUpdateList(data);
          if (response.success == true) {
            toast.success(response.message);
            handleOpen(false);
            onPress();
          } else {
            toast.error(response.msg ? response.msg : response.message);
          }
        } catch (error) {
          if (error.msg) {
            toast.error(error.msg);
          } else {
            toast.error("An error occurred");
          }
        } finally {
          setEditLoading(false);
        }
      }
    };

    return (
      <div>
        <DataGrid
          id="gridContainer"
          dataSource={dataHave}
          keyExpr="id"
          columnAutoWidth={false}
          showBorders={true}
          width="100%"
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
        >
          <Paging defaultPageSize={8} />
          <Pager visible={true} />
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <Grouping autoExpandAll={false} />

          <Column dataField="question" caption="Question" width={"40%"} />
          <Column dataField="answer" caption="Answer" width={"40%"} />
          <Column
            alignment="left"
            dataField="status"
            caption="Status"
            width={200}
            cellRender={({ data }) => (
              <div className="col-lg-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={data.enabled}
                    onChange={() => handleToggle(data.id)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            )}
          />
          <Column
            width={150}
            caption=""
            cellRender={({ data }) => (
              <Button variant="outlined" onClick={() => handleEdit(data)}>
                <img
                  src="../images/edit.png"
                  style={{
                    width: "20px",
                    filter:
                      "invert(48%) sepia(92%) saturate(6585%) hue-rotate(353deg) brightness(92%) contrast(94%)",
                  }}
                  alt=""
                />
              </Button>
            )}
          />
          <Column
            width={150}
            caption=""
            cellRender={({ data }) => (
              <Button variant="outlined" onClick={() => handleDelete(data)}>
                <img
                  src="../images/delete.png"
                  style={{
                    width: "20px",
                    filter:
                      "invert(48%) sepia(92%) saturate(6585%) hue-rotate(353deg) brightness(92%) contrast(94%)",
                  }}
                  alt=""
                />
              </Button>
            )}
          />
        </DataGrid>

        <Modal
          show={showModal}
          onHide={handleOpen}
          size="lg"
          centered
          className="custom-modalproduct"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-modalproduct-body">
            <Form onSubmit={handleSubmitEdit}>
              <Form.Group className="mb-3" controlId="newBrandName">
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter faq question"
                  value={faqQuestion}
                  onChange={(e) => setFaqQuestion(e.target.value)}
                />
                <div style={{ height: "10px" }}></div>
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter faq answer"
                  value={faqAnswer}
                  onChange={(e) => setFaqAnswer(e.target.value)}
                />
              </Form.Group>
              <div className="text-center mt-4">
                <Button
                  type="submit"
                  id="btn1"
                  variant="primary"
                  disabled={editLoading}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {editLoading && (
                      <ThreeDots color="#fff" height={20} width={30} />
                    )}
                    {!editLoading && <span>Edit</span>}
                  </div>
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  return (
    <>
      <header>
        <Header />
        <Sidebar />
      </header>
      <main className="main-panel ">
        <div className="content pt-3">
          <div className="px-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-chart">
                  <div className="card-header d-flex align-items-center">
                    <h4 className="card-title mb-0">FAQ's</h4>
                    <div className="ms-auto right_btn d-flex justify-content-end">
                      <Button id="btn" size="sm" onClick={handleAddOpen}>
                        Add FAQ
                      </Button>
                    </div>
                  </div>
                  <div className="card-body pt-2 px-3">
                    {isLoading ? (
                      <LoaderOwn />
                    ) : (
                      <FAQList dataHave={data} onPress={handleList} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </main>
      <Modal
        show={showAddModal}
        onHide={handleAddOpen}
        size="lg"
        centered
        className="custom-modalproduct"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modalproduct-body">
          <Form onSubmit={handleSubmitAdd}>
            <Form.Group className="mb-3" controlId="newBrandName">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter faq question"
                value={faqAddQuestion}
                onChange={(e) => setFaqAddQuestion(e.target.value)}
              />
              <div style={{ height: "10px" }}></div>
              <Form.Label>Answer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter faq answer"
                value={faqAddAnswer}
                onChange={(e) => setFaqAddAnswer(e.target.value)}
              />
            </Form.Group>
            <div className="text-center mt-4">
              <Button
                type="submit"
                id="btn1"
                variant="primary"
                disabled={addLoading}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {addLoading && (
                    <ThreeDots color="#fff" height={20} width={30} />
                  )}
                  {!addLoading && <span>Add</span>}
                </div>
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
