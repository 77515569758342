import React from "react";
import "./App.css";
import Login from "./pages/login";
import ProtectedRoute from "./common/protectedRoute";
import ForgotPassword from "./pages/forgotpassword";
import Dashboard from "./pages/dashboard";
import User from "./pages/user";
import Product from "./pages/product";
import Category from "./pages/Category";
import SubCategory from "./pages/SubCategory";
import PurchaseHistory from "./pages/PurchaseHistory";
import Stores from "./pages/Stores";
import WishList from "./pages/WishList";
import Logout from "./pages/Logout";
import Brands from "./pages/brands";
import Banners from "./pages/Banner";
import Competitions from "./pages/competitions";
import changedPassword from "./pages/changepassword";
import emailNotifications from "./pages/emailnotification";
import PushNotifications from "./pages/pushnotifications";
import SmsNotifications from "./pages/smsnotification";
import CompetitionDetails from "./pages/CompetitionDetails";
import UserDetails from "./pages/userDetails";
import UserTransactions from "./pages/usertransactionhistory";
import TotalEarnpointsList from "./pages/TotalEearnPointsList";
import Logindesign from "./pages/logindesign";
import WhatsAppNotifications from "./pages/whatsappnotification";
import SMTPNotifications from "./pages/smtptnotifications";
import EmailTemplate from "./pages/EmailTemplate";
import SubCategoryListPages from "./pages/subCategoryListPage";
// Route Specific
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Notifications from "./pages/Notifications";
import FaqScreen from "./pages/faq_screen";
import TermsAndCondition from "./pages/terms_and_condition";
import PrivacyPolicy from "./pages/privacy_policy";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/logindesign" element={<Logindesign />} />

          <Route
            path="/dashboard"
            element={<ProtectedRoute component={Dashboard} />}
          />
          <Route path="/user" element={<ProtectedRoute component={User} />} />
          <Route
            path="/product"
            element={<ProtectedRoute component={Product} />}
          />
          <Route
            path="/category"
            element={<ProtectedRoute component={Category} />}
          />
          <Route
            path="/subcategory/:id"
            element={<ProtectedRoute component={SubCategory} />}
          />
          <Route
            path="/purchase-history"
            element={<ProtectedRoute component={PurchaseHistory} />}
          />
          <Route
            path="/Stores"
            element={<ProtectedRoute component={Stores} />}
          />
          <Route
            path="/wishlist"
            element={<ProtectedRoute component={WishList} />}
          />
          <Route
            path="/pushnotifications"
            element={<ProtectedRoute component={PushNotifications} />}
          />
          <Route
            path="/logout"
            element={<ProtectedRoute component={Logout} />}
          />

          <Route
            path="/brands"
            element={<ProtectedRoute component={Brands} />}
          />
          <Route
            path="/banners"
            element={<ProtectedRoute component={Banners} />}
          />
          <Route
            path="/competitions"
            element={<ProtectedRoute component={Competitions} />}
          />
          <Route
            path="/change-password"
            element={<ProtectedRoute component={changedPassword} />}
          />
          <Route
            path="/emailNotifications"
            element={<ProtectedRoute component={emailNotifications} />}
          />
          <Route
            path="/sms-notifications"
            element={<ProtectedRoute component={SmsNotifications} />}
          />
          <Route
            path="/whatsapp-notifications"
            element={<ProtectedRoute component={WhatsAppNotifications} />}
          />
          <Route
            path="/SMT-notifications"
            element={<ProtectedRoute component={SMTPNotifications} />}
          />
          <Route
            path="/competitions/:id"
            element={<ProtectedRoute component={CompetitionDetails} />}
          />
          <Route
            path="/user/:userId"
            element={<ProtectedRoute component={UserDetails} />}
          />
          <Route
            path="/user-transactions/:userId"
            element={<ProtectedRoute component={UserTransactions} />}
          />
          <Route
            path="/total-earnpoints-list/:userId"
            element={<ProtectedRoute component={TotalEarnpointsList} />}
          />
          <Route
            path="/notifications"
            element={<ProtectedRoute component={Notifications} />}
          />
          <Route
            path="/email-template"
            element={<ProtectedRoute component={EmailTemplate} />}
          />
          <Route
            path="/subcategory-list"
            element={<ProtectedRoute component={SubCategoryListPages} />}
          />
          <Route
            path="/faqs"
            element={<ProtectedRoute component={FaqScreen} />}
          />
          <Route
            path="/terms-and-condition"
            element={<ProtectedRoute component={TermsAndCondition} />}
          />
          <Route
            path="/privacy-policy"
            element={<ProtectedRoute component={PrivacyPolicy} />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
