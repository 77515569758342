const LoadingScreen = () => {
  return (
    <div style={styles.container}>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        style={{ marginRight: "1rem" }}
      ></span>

      <div style={styles.loader}>Loading...</div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#f0f0f0",
  },
  loader: {
    fontSize: "24px",
    color: "#333",
  },
};

export default LoadingScreen;
