import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../auth/authService";
import LoadingMain from "./loadingMain";

const ProtectedRoute = ({ component: Component }) => {
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await isAuthenticated();
      setIsAuth(authStatus);
    };
    checkAuth();
  }, []);

  if (isAuth === null) {
    return <LoadingMain />;
  }

  return isAuth ? <Component /> : <Navigate to="/" />;
};

export default ProtectedRoute;
